<template>
	<v-row class="pb-3" dense>
		<v-col cols="12" class="pt-0">
			<label>{{ label }}</label>
		</v-col>
		<v-col cols="12" class="pb-0">
			<v-text-field
				single-line
				outlined
				dense
				height="48"
				color="#CACEDA"
				type="text"
				v-mask="mask"
				:placeholder="placeholder"
				v-model="displayValue"
				:rules="rules"
			></v-text-field>
		</v-col>
	</v-row>
</template>

<script>
import { mask } from "vue-the-mask";
export default {
	props: {
		label: String,
		placeholder: String,
		value: String,
		rules: Array
	},

	data() {
		return {
			mask: "(###)-###-####"
		};
	},

	computed: {
		displayValue: {
			get() {
				return this.value;
			},
			set(setvalue) {
				this.$emit("input", setvalue);
			}
		}
	}
};
</script>